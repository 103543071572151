<template>
  <div class="content">
    <!-- 券 -->
    <van-dialog class="ticketDialog" v-model="ticketVisible" :showConfirmButton="false">
      <div class="ticket">
        <h2>{{ money }}</h2>
        <img class="ticket_btn" src="@/assets/img/ticket_btn.png" alt="" @click="ticketClose" />
        <img class="ticket_close" src="@/assets/img/ticket_close.png" alt="" @click="ticketClose" />
      </div>
    </van-dialog>
    <!-- 好评弹窗 -->
    <van-dialog class="commentDialog" v-model="ticketVisible2" :showConfirmButton="false">
      <template #title>
        <p class="rise-limit-title">Selamat</p>
      </template>
      <p class="tip">
        Dana pinjaman telah masuk ke rekening Anda. Pujian bintang lima dapat mengurangi bunga dan menambah jumlah
        pinjaman minimal Rp300.000!
      </p>
      <div class="toStore-btn" @click="ticketVisible2 = false">
        <a href="https://apps.apple.com/id/app/scde-dompet-kredit/id6742474393?l=id">
          Ulasan bagus
        </a>
      </div>
      <img class="hiddenIcon" src="@/assets/img/hiddenIcon.png" @click="ticketVisible2 = false" />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast } from 'vant'
Vue.use(Toast)
export default {
  props: {
    money: {
      default: 'Rp0'
    }
  },
  data () {
    return {
      ticketVisible: false,
      ticketVisible2: false

    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  created () {
    // 券
    if (!localStorage.getItem('showTicket')) {
      this.ticketVisible = true
    }
  },
  methods: {
    // 券弹窗关闭
    ticketClose () {
      Toast('Berhasil ambil, gunakan pada pinjaman berikutnya')
      localStorage.setItem('showTicket', true)
      setTimeout(() => {
        this.ticketVisible = false
        try {
          if (window.webkit.messageHandlers.udhajs) {
            window.webkit.messageHandlers.udhajs.postMessage('')
            return
          }
          this.ticketVisible2 = true
        } catch (e) {
          this.ticketVisible2 = true
        }
      }, 700)
    }
  }
}
</script>

<style scoped lang='less'>
.commentDialog {
  .rise-limit-title {
    .dialog-title;
    font-size: 28px;
    padding-left: 24px;
    text-align: left;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 30px;
    font-size: 26px;
    color: #999;
    text-align: center;
  }

  .toStore-btn {
    width: 400px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 80px auto 84px;
    font-size: 30px;
    color: #fff;
    background-color: @theme-color;
    text-align: center;

    a {
      color: #fff;
    }
  }

  .hiddenIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.ticketDialog {
  background: none;
  padding-bottom: 250px;

  .ticket {
    height: 900px;
    background: none;
    position: relative;
    background: url("~@/assets/img/ticket_bg.png") no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 9;

    h2 {
      text-align: center;
      padding-top: 650px;
      color: #ea0923;
      font-size: 36px;
    }

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .ticket_btn {
      bottom: -110px;
      height: 100px;
    }

    .ticket_close {
      bottom: -250px;
      width: 100px;
    }
  }
}

/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}
</style>
